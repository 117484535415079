<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="imgLogo2"
          alt="Logo New X Coins"
          style="max-height: 10rem"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex remove-margin" style="max-height: 100vh;">
        <b-img
          fluid
          :src="imgUrl"
          alt="ForgotPassword V2"
          style="width: 100%; max-height: 100vh; object-fit: cover;"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div v-if="!emailSent">
            <b-card-title class="mb-1">
              {{ $t("Esqueceu sua senha?") }} 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              {{
                $t(
                  "Insira seu e-mail e enviaremos instruções para redefinir sua senha"
                )
              }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="simpleRules" #default="{ invalid }">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
              >
                <b-form-group
                  :label="labelForgotPassword"
                  label-for="forgot-password-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-email"
                      placeholder="example@gmail.com"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : emailError && emailError
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || loading"
                >
                  <div v-if="!loading">
                    {{ $t("Enviar link de redefinição") }}
                  </div>
                  <div v-else>
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t("Carregando...") }}
                  </div>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <div v-else class="animate__animated animate__fadeIn">
            <sweetalert-icon
              icon="success"
              class="animate__animated animate__backInDown"
            />
            <b-card-title class="mb-1 text-success">
              {{ apiResponseForgotPassword }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{
                $t(
                  "Confira em sua caixa de entrada o link para a alteração da senha de acesso"
                )
              }}
            </b-card-text>
          </div>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t("Volte ao login") }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ProjectLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      labelForgotPassword: this.$i18n.t("E-mail"),
      loading: false,
      emailError: "",
      emailSent: false,
      apiResponseForgotPassword: "",

      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      return require("@/assets/images/business_plan/forgot_password.jpg");
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            email: this.userEmail.toLowerCase(),
          };
          this.forgotPassword(data)
            .then((response) => {
              this.apiResponseForgotPassword = response.message;
              this.emailSent = true;
              this.loading = false;
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  title: this.$i18n.t("Erro na autenticação"),
                  text: error,
                },
              });
              this.emailError = error;
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.remove-margin {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.auth-inner .b-img {
  object-fit: cover;
  width: 100%;
  max-height: 100vh; /* Defina a altura máxima como a altura da visualização */
}
</style>
